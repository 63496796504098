<template>
  <svg
    class="h-7 w-5 sm:h-11 sm:w-8"
    viewBox="0 0 84 116"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="icono dieta keto"
    :class="fill"
  >
    <path
      d="M83.9 67.4C83.8 81.2 79.2 93.2 70 103.4C64.1 109.9 56.7 113.9 47.9 115.4C40.1 116.7 32.7 115.7 25.7 112.2C20.4 109.6 15.9 105.9 12.1 101.5C6.20001 94.6 2.6 86.7 1 77.8C0.300001 73.7 -0.0999928 69.5 0.100007 65.3C0.300007 57.9 2 50.9 5.2 44.3C6.5 41.5 8.10001 38.9 9.60001 36.2C12.3 31.1 14.4 25.8 16.4 20.3C18 15.9 20.2 11.9 23.5 8.5C28.3 3.7 34 0.599995 40.9 0.199995C47.9 -0.200005 53.9 2.3 59 7.1C63.2 11 66.1 15.8 68 21.2C70.3 28.1 73.5 34.7 77.2 41.1C80.6 47 82.7 53.4 83.5 60.3C83.8 62.7 83.9 65.1 84 67.5L83.9 67.4ZM72.7 67.6C72.7 66.8 72.7 66.1 72.7 65.3C72.7 64.8 72.7 64.4 72.7 63.9C72.2 57.6 70.5 51.6 67.3 46.1C63.4 39.3 60 32.3 57.5 24.9C56.3 21.2 54.3 18 51.6 15.3C46.4 10.2 39.1 9.8 33.5 14.3C30.1 17 27.9 20.4 26.6 24.5C24.6 30.3 22.3 35.9 19.4 41.3C18 43.9 16.5 46.3 15.2 48.9C11.6 56.6 10.3 64.7 11.4 73.2C12.6 82.9 16.6 91.1 23.8 97.6C31.4 104.5 40.4 106.4 50.2 103.5C55.9 101.8 60.3 98.2 64 93.5C69.9 85.9 72.9 77.2 72.8 67.5L72.7 67.6Z"
    />
    <path
      d="M19.8 67.2C19.8 60.4 21.4 54 24.8 48.2C27 44.5 29.8 41.4 33.6 39.4C39.9 36 47.6 36.7 53.2 41.2C58 45.1 61.1 50.2 62.7 56.1C64.9 64 65 72 62.3 79.8C60.5 85 57.7 89.6 53.4 93.2C47.6 98.1 38.7 99.4 31.2 93.6C25.9 89.5 22.8 84.1 21.2 77.7C20.3 74.2 19.8 70.7 19.8 67.1V67.2Z"
    />
  </svg>
</template>

<script setup lang="ts">
  interface Props {
    fill?: string;
  }

  withDefaults(defineProps<Props>(), {
    fill: "#FFCF03",
  });
</script>
